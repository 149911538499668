/* SOURCE SANS PRO */
@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
    url('../assets/fonts/SourceSansPro-Bold.woff2') format('woff2'),
    url('../assets/fonts/SourceSansPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'),
    url('../assets/fonts/SourceSansPro-ExtraLight.woff2') format('woff2'),
    url('../assets/fonts/SourceSansPro-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro ExtraLight Italic'),
    local('SourceSansPro-ExtraLightItalic'),
    url('../assets/fonts/SourceSansPro-ExtraLightItalic.woff2') format('woff2'),
    url('../assets/fonts/SourceSansPro-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackItalic'),
    url('../assets/fonts/SourceSansPro-BlackItalic.woff2') format('woff2'),
    url('../assets/fonts/SourceSansPro-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
    url('../assets/fonts/SourceSansPro-Regular.woff2') format('woff2'),
    url('../assets/fonts/SourceSansPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
    url('../assets/fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/SourceSansPro-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro Black'), local('SourceSansPro-Black'),
    url('../assets/fonts/SourceSansPro-Black.woff2') format('woff2'),
    url('../assets/fonts/SourceSansPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
    url('../assets/fonts/SourceSansPro-Italic.woff2') format('woff2'),
    url('../assets/fonts/SourceSansPro-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro Light';
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
    url('../assets/fonts/SourceSansPro-Light.woff2') format('woff2'),
    url('../assets/fonts/SourceSansPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'),
    url('../assets/fonts/SourceSansPro-LightItalic.woff2') format('woff2'),
    url('../assets/fonts/SourceSansPro-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'),
    url('../assets/fonts/SourceSansPro-BoldItalic.woff2') format('woff2'),
    url('../assets/fonts/SourceSansPro-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro SemiBold Italic'),
    local('SourceSansPro-SemiBoldItalic'),
    url('../assets/fonts/SourceSansPro-SemiBoldItalic.woff2') format('woff2'),
    url('../assets/fonts/SourceSansPro-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Sans Pro Regular';
  src: url('../assets/fonts/SourceSansPro-Regular.woff2') format('woff2'),
    url('../assets/fonts/SourceSansPro-Regular.woff') format('woff');
}

/* PUBLICO */
@font-face {
  font-family: 'Publico Black';
  font-weight: bold;
  src: url('../assets/fonts/Publico/Publico-Black.otf') format('opentype');
}
@font-face {
  font-family: 'Publico Italic';
  src: url('../assets/fonts/Publico/Publico-Italic.otf') format('opentype');
}
@font-face {
  font-family: 'Publico Roman';
  src: url('../assets/fonts/Publico/Publico-Roman.otf') format('opentype');
}
@font-face {
  font-family: 'Publico Banner';
  src: url('../assets/fonts/Publico/Publico-Banner-Bold.ttf') format('truetype');
}

/* GOOGLE LATO */
@font-face {
  font-family: 'latoblack';
  src: url('../assets/fonts/Lato/lato-black-webfont.woff2') format('woff2'),
       url('../assets/fonts/Lato/lato-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'latobold';
  src: url('../assets/fonts/Lato/lato-bold-webfont.woff2') format('woff2'),
       url('../assets/fonts/Lato/lato-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'latolight';
  src: url('../assets/fonts/Lato/lato-light-webfont.woff2') format('woff2'),
       url('../assets/fonts/Lato/lato-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'latoregular';
  src: url('../assets/fonts/Lato/lato-regular-webfont.woff2') format('woff2'),
       url('../assets/fonts/Lato/lato-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
